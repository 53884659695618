<template>
	<SectionHeader :image="null" title="Verein" page="Über uns" />
	<div class="container">
		<div class="row text-center p-4">
			<div class="col">
				<i class="text-success fad fa-2x fa-user" />
				<h2 class="fw-light">324</h2>
				<p>Mitglieder</p>
			</div>
			<div class="col">
				<i class="text-success fad fa-2x fa-flag" />
				<h2 class="fw-light">2009</h2>
				<p>Gründung</p>
			</div>
		</div>
		<hr>
		<h1 class="display-6 fw-light mb-2 text-center">Der Verlauf über die Jahre</h1>
		<div class="row">
			<div class="col-xl-12">
				<ul class="timeline-list">
					<li v-for="item in Timeline" :key="item.id">
						<list-loader v-if="loading" viewBox="0 0 400 160" primaryColor="rgb(81, 207, 102)" :speed="2" :animate="true" />
						<div v-else class="timeline_content card border-success border-2">
							<span class="display-7 text-secondary" v-if="item.year" v-text="item.year" />
							<h4 class="fw-light" v-if="item.title" v-text="item.title" />
							<p v-if="item.text" v-html="item.text" />
						</div>
					</li>
				</ul>
			</div>
		</div>
		<p/>
	</div>
</template>
<script>
import SectionHeader from '@/views/SectionHeader.vue'
import { ListLoader } from 'vue-content-loader'
export default {
	name: 'VereinAbout',
	components: {
		SectionHeader,
		ListLoader
	},
	created() {
		this.$store.dispatch('loadTimeline');
	},
	computed: {
		Timeline()
		{
			return this.$store.state.timeline;
		},
		loading()
		{
			return this.$store.state.loadingStatus;
		}
	},
}
</script>
<style scoped>
ul.timeline-list {
	position: relative;
	margin: 0;
	padding: 0
}
ul.timeline-list:before {
	position: absolute;
	content: "";
	width: 2px;
	height: 100%;
	background-color: #3a3a3a;
	left: 50%;
	top: 0;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}
ul.timeline-list li {
	position: relative;
	clear: both;
	display: table;
}
ul.timeline-list li .timeline_content {
	width: 45%;
	color: #333;
	padding: 30px;
	float: left;
	text-align: right;
}
ul.timeline-list li:nth-child(2n) .timeline_content {
	float: right;
	text-align: left;
}
ul.timeline-list li:before {
	position: absolute;
	content: "";
	width: 25px;
	height: 25px;
	background-color: #51cf66;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	border-radius: 50%;
}
</style>
